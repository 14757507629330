import { createAPIAction } from 'store/utils/createAPIAction';
import { createAction } from 'store/utils/createAction';
import { ZendeskAmazonQuote } from 'entities/ZendeskAmazonQuote';
import queryString from 'query-string';

const serviceName = 'fleet';

export const getFleetInfo = createAPIAction(
  `${serviceName}/GET_FLEET_INFO`,
  ({ fleetId }) => ({
    endpoint: `/consumer-service/consumer/fleet/${fleetId}`,
  })
);

export const getFleetAppointments = createAPIAction(
  `${serviceName}/GET_FLEET_APPOINTMENTS`,
  ({ fleetId, page, size, sort, data }) => ({
    endpoint: `/repair-service/consumer/fleets/${fleetId}/repair-requests?page=${page}&size=${size}&sort=${
      sort === null ? '' : sort
    }`,
    method: 'POST',
    data,
  })
);

export const setFleetAppointmentsEmpty = createAction(
  `${serviceName}/SET_FLEET_APPOINTMENTS_EMPTY`
);

export const clearFleetAppointments = createAction(
  `${serviceName}/CLEAR_FLEET_APPOINTMENTS`
);

export const getFleetInvoices = createAPIAction(
  `${serviceName}/GET_FLEET_INVOICES`,
  ({ fleetId, states, page, size, sort, data }) => ({
    endpoint: `/repair-service/consumer/fleets/${fleetId}/repair-requests?page=${page}&size=${size}${
      sort ? `&sort=${sort}` : ''
    }`,
    method: 'POST',
    data: {
      state: states,
      ...data,
    },
  })
);

export const clearFleetInvoices = createAction(
  `${serviceName}/CLEAR_FLEET_INVOICES`
);

export const getFleetMaintenance = createAPIAction(
  `${serviceName}/GET_FLEET_MAINTENANCE`,
  ({ fleetId, page, size, sort, data }) => ({
    endpoint: `/repair-service/consumer/fleets/${fleetId}/maintenance?page=${page}&size=${size}&sort=${
      sort === null ? '' : sort
    }`,
    method: 'POST',
    data,
  })
);

export const getFleetSummary = createAPIAction(
  `${serviceName}/GET_FLEET_SUMMARY`,
  ({ fleetId }) => ({
    endpoint: `/repair-service/consumer/fleets/${fleetId}/summary`,
  })
);

export const clearFleetMaintenance = createAction(
  `${serviceName}/CLEAR_FLEET_MAINTENANCE`
);

export const getFleetCanceled = createAPIAction(
  `${serviceName}/GET_FLEET_CANCELED`,
  ({ fleetId, page, size, sort, data }) => ({
    endpoint: `/repair-service/consumer/fleets/${fleetId}/canceled?${queryString.stringify(
      { page, size, sort }
    )}`,
    method: 'POST',
    data,
  })
);

export const getFleetCompliance = createAPIAction(
  `${serviceName}/GET_FLEET_COMPLIANCE`,
  ({ fleetId, page, size, sort, data }) => ({
    endpoint: `/repair-service/consumer/fleets/${fleetId}/compliance-inspections?${queryString.stringify(
      { page, size, sort }
    )}`,
    method: 'POST',
    data,
  })
);

export const getFleetQuotes = createAPIAction(
  `${serviceName}/GET_FLEET_QUOTES`,
  ({ fleetId, page, size, sort, data }) => ({
    endpoint: `/repair-service/consumer/fleets/${fleetId}/quotes?${queryString.stringify(
      { page, size, sort }
    )}`,
    method: 'POST',
    data,
  })
);

export const getFleetCarCount = createAPIAction(
  `${serviceName}/GET_FLEET_CAR_COUNT`,
  ({ fleetId, serviceType, data }) => ({
    endpoint: `/repair-service/consumer/fleets/${fleetId}/cars-count?serviceType=${serviceType}`,
    method: 'POST',
    data,
  })
);

export const getFleetComplianceCounts = createAPIAction(
  `${serviceName}/GET_FLEET_COMPLIANCE_COUNTS`,
  ({ fleetId, data }) => ({
    endpoint: `/repair-service/consumer/fleets/${fleetId}/compliance-services-count`,
    method: 'POST',
    data,
  })
);

export const getFleetRecommended = createAPIAction(
  `${serviceName}/GET_FLEET_RECOMMENDED`,
  ({ fleetId, page, size, sort, data }) => {
    return {
      endpoint: `/repair-service/consumer/fleets/${fleetId}/recommended?page=${page}&size=${size}${
        sort ? `&sort=${sort}` : ''
      }`,
      method: 'POST',
      data,
    };
  }
);

export const getFleetRecommendedServicesCount = createAPIAction(
  `${serviceName}/GET_FLEET_RECOMMENDED_SERVICES_COUNT`,
  ({ fleetId, data }: { fleetId: string; data: any }) => {
    return {
      endpoint: `/repair-service/consumer/fleets/${fleetId}/recommended-repairs-count`,
      method: 'POST',
      data,
    };
  }
);

export const setFleetRecommended = createAction(
  `${serviceName}/SET_FLEET_RECOMMENDED`
);

export const clearFleetRecommended = createAction(
  `${serviceName}/CLEAR_FLEET_RECOMMENDED`
);

export const setFleetCarFilter = createAction(
  `${serviceName}/SET_FLEET_CAR_FILTER`
);

export const getZendeskTicketByTaskType = createAPIAction(
  `${serviceName}/GET_ZENDESK_TICKET`,
  (referenceNum: string, taskType: string) => {
    return {
      endpoint: `/repair-service/zendesk/tickets?referenceNumber=${referenceNum}&taskType=${taskType}`,
      method: 'GET',
    };
  }
);

export const sendFMCAuthZendeskTicket = createAPIAction(
  `${serviceName}/SEND_FMC_AUTH_ZENDESK_TICKET`,
  (referenceNum: string) => {
    return {
      endpoint: `/repair-service/zendesk/tickets/${referenceNum}/fmc-authorization-request`,
      method: 'POST',
    };
  }
);

export const sendAmazonQuoteZendeskTicket = createAPIAction(
  `${serviceName}/SEND_AMAZON_ZENDESK_TICKET`,
  (ticketData: ZendeskAmazonQuote) => {
    return {
      endpoint: '/repair-service/zendesk/tickets',
      method: 'POST',
      data: ticketData,
    };
  }
);

export const getVisitSlots = createAPIAction(
  `${serviceName}/GET_VISIT_SLOTS`,
  ({ fleetId, maxLeadTime }: { fleetId: string; maxLeadTime: number }) => {
    return {
      endpoint: `/repair-service/consumer/visits/${fleetId}/selection-page?maxLeadTime=${maxLeadTime}`,
    };
  }
);

export const getWorkOrderVisit = createAPIAction(
  `${serviceName}/GET_WORK_ORDER_VISIT`,
  ({ workOrderId }: { workOrderId: string }) => {
    return {
      endpoint: `/repair-service/consumer/visits/${workOrderId}/visits`,
    };
  }
);

export const sendFleetRequest = createAPIAction(
  `${serviceName}/SEND_FLEET_REQUEST`,
  ({
    repairOrderId,
    visitRefNum,
  }: {
    visitRefNum: string;
    repairOrderId: string;
  }) => {
    return {
      endpoint: `/repair-service/consumer/visits/${visitRefNum}/repair-order`,
      method: 'PUT',
      data: {
        repairOrderId,
      },
    };
  }
);

export const rescheduleFleetRequest = createAPIAction(
  `${serviceName}/RESCHEDULE_FLEET_REQUEST`,
  ({
    repairOrderId,
    visitRefNum,
  }: {
    visitRefNum: string;
    repairOrderId: string;
  }) => {
    return {
      endpoint: `/repair-service/consumer/visits/${visitRefNum}/repair-order`,
      method: 'PATCH',
      data: {
        repairOrderId,
      },
    };
  }
);

export const removeWorkOrderFromVisit = createAPIAction(
  `${serviceName}/CANCEL_FLEET_VISIT`,
  ({
    visitRefNum,
    workOrderId,
    customerCancellationReason,
    otherCancellationReason,
    appointmentResolutionId,
  }: {
    visitRefNum: any;
    workOrderId: string;
    customerCancellationReason?: string;
    otherCancellationReason?: string;
    appointmentResolutionId?: string;
  }) => {
    return {
      endpoint: `/repair-service/consumer/visits/${visitRefNum}/work-orders/${workOrderId}`,
      method: 'DELETE',
      data: {
        customerCancellationReason,
        otherCancellationReason,
        appointmentResolutionId,
      },
    };
  }
);

export const getVisit = createAPIAction(
  `${serviceName}/GET_VISIT`,
  ({ visitRefNum }: { visitRefNum: string }) => {
    return {
      endpoint: `/repair-service/consumer/visits/${visitRefNum}`,
    };
  }
);

export const getFleetVisitsByMonth = createAPIAction(
  `${serviceName}/GET_FLEET_VISITS_BY_MONTH`,
  ({ fleetId, month, year }) => {
    const params = `month=${month}&year=${year}`;
    return {
      endpoint: `/repair-service/consumer/visits/${fleetId}/by-year-month?${params}`,
    };
  }
);

export const setFleetVehicleDetailsTab = createAction(
  `${serviceName}/SET_FLEET_VEHICLE_DETAILS_TAB`
);

export const setFleetVehicleDetailsAppointmentsTab = createAction(
  `${serviceName}/SET_FLEET_VEHICLE_DETAILS_APPOINTMENTS_TAB`
);

export const getVisitsForSelectionPage = createAPIAction(
  `${serviceName}/GET_VISITS_FOR_SELECTION_PAGE`,
  ({
    fleetId,
    data,
    maxLeadTime,
  }: {
    fleetId: string;
    data: string;
    maxLeadTime: number;
  }) => {
    return {
      endpoint: `/repair-service/consumer/visits/${fleetId}/selection-page?maxLeadTime=${maxLeadTime}&${data}`,
    };
  }
);

export const setSelectedInvoices = createAction(
  `${serviceName}/SELECT_INVOICES`
);
