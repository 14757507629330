import {
  MicrosoftService,
  MicrosoftResponse,
  LocationResponse,
  AutosuggestResponse,
  TimeZoneResponse,
} from 'entities/Bingmaps';
import { GeoData } from 'entities/Geodata';

const { BING_MAPS_API_KEY } = window.RepairSmith;

const getEndpoint = (
  service: MicrosoftService,
  opts?: {
    fragments?: string[];
    params?: Record<string, string | number>;
  }
) => {
  const fragments = opts?.fragments
    ? `/${opts.fragments.map((fragment) => encodeURIComponent(fragment)).join('/')}`
    : '';
  const params: Record<string, string | number> = {
    ...(opts?.params || {}),
    key: BING_MAPS_API_KEY as string,
  };
  const strParams = Object.keys(params).reduce((str, key, idx, array) => {
    str += `${key}=${params[key]}`;
    if (idx < array.length - 1) {
      str = `${str}&`;
    }
    return str;
  }, '?');
  return `https://dev.virtualearth.net/REST/v1/${service}${fragments}${strParams}`;
};

const handleMicrosoftRequest = async <T>(url: string) => {
  const response = await fetch(url);
  const body: MicrosoftResponse<T> = await response.json();

  if (body.errorDetails) {
    throw new Error(body.errorDetails[0]);
  }

  return body.resourceSets[0].resources[0];
};

export const getMicrosoftSuggestionsFromQuery = async (
  query: string,
  opts?: { maxResults?: number }
) => {
  const url = getEndpoint(MicrosoftService.Autosuggest, {
    params: {
      query: encodeURIComponent(query),
      countryFilter: 'US',
      ...(opts || {}),
    },
  });
  return handleMicrosoftRequest<AutosuggestResponse>(url);
};

const getMicrosoftLocationFromQuery = async (
  query: string,
  opts?: { maxResults?: number }
) => {
  const url = getEndpoint(MicrosoftService.Locations, {
    fragments: [query],
    params: opts,
  });
  return handleMicrosoftRequest<LocationResponse>(url);
};

const getMicrosoftTimeZone = async (lat: number, lng: number) => {
  const url = getEndpoint(MicrosoftService.TimeZone, {
    fragments: [`${lat},${lng}`],
  });
  return handleMicrosoftRequest<TimeZoneResponse>(url);
};

const sanitizeBingZipcode = (zip = '', remove4Code = true) => {
  return remove4Code ? zip?.split('-')[0] : zip;
};

export const geocodeFromAddress = async (
  query?: string | null
): Promise<GeoData | null> => {
  if (!query) {
    return null;
  }
  const topSuggestionResponse = await getMicrosoftSuggestionsFromQuery(query, {
    maxResults: 1,
  });
  const topSuggestion = topSuggestionResponse.value[0];

  const response = await getMicrosoftLocationFromQuery(
    topSuggestion.address.formattedAddress,
    {
      maxResults: 1,
    }
  );

  return {
    status: 'OK',
    results: [
      {
        address_components: [
          {
            long_name: sanitizeBingZipcode(topSuggestion.address.postalCode),
            short_name: sanitizeBingZipcode(topSuggestion.address.postalCode),
            types: ['postal_code'],
          },
          {
            long_name: topSuggestion.address.locality,
            short_name: topSuggestion.address.locality,
            types: ['locality'],
          },
          {
            long_name: topSuggestion.address.neighborhood,
            short_name: topSuggestion.address.neighborhood,
            types: ['neighborhood'],
          },
          {
            long_name: topSuggestion.address.adminDistrict2,
            short_name: topSuggestion.address.adminDistrict2,
            types: ['administrative_area_level_2'],
          },
          {
            long_name: topSuggestion.address.adminDistrict,
            short_name: response.address.adminDistrict,
            types: ['administrative_area_level_1'],
          },
          {
            long_name: topSuggestion.address.countryRegion,
            short_name: topSuggestion.address.countryRegionIso2,
            types: ['country'],
          },
          ...(topSuggestion.__type === 'Address'
            ? [
                {
                  long_name: topSuggestion.address.houseNumber,
                  short_name: topSuggestion.address.houseNumber,
                  types: ['street_number'],
                },
                {
                  long_name: topSuggestion.address.streetName,
                  short_name: topSuggestion.address.streetName,
                  types: ['route'],
                },
              ]
            : []),
        ],
        formatted_address: topSuggestion.address.formattedAddress,
        geometry: {
          bounds: {
            northeast: { lat: response.bbox[2], lng: response.bbox[3] },
            southwest: { lat: response.bbox[0], lng: response.bbox[1] },
          },
          viewport: {
            northeast: { lat: response.bbox[2], lng: response.bbox[3] },
            southwest: { lat: response.bbox[0], lng: response.bbox[1] },
          },
          location: {
            lat: response.point.coordinates[0],
            lng: response.point.coordinates[1],
          },
          location_type: '',
        },
        place_id: topSuggestion.address.formattedAddress,
        postcode_localities: [],
        types: [],
      },
    ],
  };
};

export const getCoordinates = (geoData: GeoData): [number, number] => {
  const { lng = 0, lat = 0 } = geoData?.results[0]?.geometry?.location ?? {};
  return [lng, lat];
};

export const getTimezone = async (lat: number, lng: number) => {
  try {
    const result = await getMicrosoftTimeZone(lat, lng);
    return result.timeZone.ianaTimeZoneId;
  } catch (err) {
    console.error('Error fetching timezone data:', err);
  }
};

export const getLatLng = async (data: GeoData['results'][0]) => {
  return new Promise<{ lat: number; lng: number }>((resolve, reject) => {
    if (data.geometry.location.lat && data.geometry.location.lng) {
      const { lat, lng } = data.geometry.location;
      resolve({ lat, lng });
    } else {
      reject();
    }
  });
};
