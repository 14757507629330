import { handleActions } from 'redux-actions';
import { createSimpleHandler } from 'store/utils/createSimpleHandler';
import { createClearHandler } from 'store/utils/createClearHandler';
import { isNil, last, cloneDeep } from 'lodash';
import {
  setStartPath,
  clearRequestInfo,
  setIsDataFromQuery,
  setCarSelectionAttr,
  clearCarSelectionAttr,
  resetCarSelection,
  setCarImage,
  setDeliveryAddress,
  setDeliveryCoordinates,
  setShop,
  setZip,
  setVisit,
  setTimezone,
  clearServices,
  addKnownRepair,
  removeKnownRepair,
  clearKnownRepairs,
  addDiagnosis,
  clearDiagnoses,
  addCustomServiceRequest,
  removeCustomServiceRequest,
  updateServicesQuote,
  updateServices,
  setHoursToFirstAvailableAppointment,
  setNote,
  saveNote,
  getNote,
  updateNote,
  deleteNote,
  setCarDecodeError,
  setDeclineRepairs,
  clearDeclineRepairs,
  setRepairRequestError,
  saveBookingDataForLater,
  throwAutoBookShownEvent,
  setThrowAutoBookShownEvent,
  setArrivalInstructions,
  setDeliveryAddressIsCustom,
  setCoordinates,
  setRequestCredit,
  saveAppointmentPreference,
  saveBillingAddress,
  saveResponsiblePartyId,
} from 'store/preliminaryRepair/actions';
import {
  GetMotorPricingPayload,
  getPrices,
  RequestMetadataPayload,
} from 'store/repairs/actions';
import { signOut } from 'store/auth/actions';
import { DeliveryChannel } from 'entities/RepairRequest';
import {
  KnowRepair,
  KnowRepairWithPrice,
  PreliminaryKnowRepair,
} from 'entities/KnowRepair';
import { Moment } from 'moment';
import { PreliminaryCar } from 'entities/PreliminaryCar';
import { Diagnose } from 'entities/Diagnose';
import { Shop } from 'entities/Shop';
import { CustomRequest } from 'entities/CustomRequest';
import { RepairDailyCount } from 'entities/RepairDailyCount';
import { Address } from 'entities/Address';
import { Coordinates } from 'entities/Coordinates';
import { Note } from 'entities/Note';
import { Credit } from 'entities/Credit';
import { Visit } from 'entities/Visit';
import { BookingData } from 'entities/Booking';
import { Coordinate } from '../shops/types';
import { BillingAddress } from '../../entities/BillingAddress';

const defaultServices = {
  knownRepairs: [],
  diagnoses: [],
  customRequests: [],
};

export const defaultCar = {
  byLicensePlate: false,
  year: {
    id: null,
  },
  make: {
    id: null,
  },
  model: {
    id: null,
  },
  trim: {
    id: null,
  },
  engine: {
    id: null,
  },
  mileage: {
    value: null,
  },
  error: undefined,
};

export type Services = {
  knownRepairs: (PreliminaryKnowRepair | KnowRepairWithPrice)[];
  diagnoses: Diagnose[];
  customRequests: string[];

  shopId?: number;
  totalCost?: number | null;
  totalLaborDuration?: number | null;
  fleetId?: string | null;
  responsiblePartyId?: string | null;
};

export type AddRepairRequestResponse = {
  repairRequestId: string;
  referenceNum?: string;
  repairOrderId: string;
  appointmentId: string;
  services: {
    knownRepairs: KnowRepair[];
    removedRepairs?: KnowRepair[];
    diagnoses: Diagnose[];
    removedDiagnoses?: Diagnose[];
    customRequests: CustomRequest[];
    removedCustomRequests?: CustomRequest[];
  };
  drivingDistanceMiles: number | null;
  totalCost: number | null;
};

export interface PreliminaryRepairStoreState {
  startPath?: string;
  repairDailyCount?: RepairDailyCount;
  isDataFromQuery?: boolean;
  car?: PreliminaryCar;
  delivery?: {
    address?: {
      streetLine1: string;
      timezone: string;
      city: string;
      state: string;
      country: string;
      zip: string;
      fullAddress: string;
    };
    arrivalInstructions?: string;
    channel?: DeliveryChannel;
    coordinates?: Coordinates;
    firstAvailableAppointment?: number;
    addressIsCustom?: boolean;
    hoursToFirstAvailableAppointment?: number;
  };
  shop?: Shop;
  zip?: string;
  visit?: Visit;
  timezone?: string;
  services: Services;
  requestMetadata?: RequestMetadataPayload;
  note?: Note;
  savedBookingData?: BookingData;
  declineRepairs?: string[];
  repairRequestError?: { message: string };
  scheduledDateTime?: any;
  thrownAutoBookShownEvent?: boolean;
  coordinates?: Coordinate;
  credit?: Credit;
  appointmentPreference?: {
    date: Moment;
    time: string;
    foundSlotDay: any;
    foundSlotTime: any;
  };
  billingAddress?: BillingAddress;
  responsiblePartyId?: string;
}

const defaultState: PreliminaryRepairStoreState = {
  startPath: undefined,
  car: defaultCar,
  delivery: undefined,
  shop: undefined,
  services: { ...defaultServices },
  requestMetadata: undefined,
  note: undefined,
  repairRequestError: undefined,
  thrownAutoBookShownEvent: false,
  coordinates: undefined,
  credit: undefined,
  appointmentPreference: undefined,
  billingAddress: undefined,
  responsiblePartyId: undefined,
};

const getLastIndexSafely = (arr: any[]) => {
  if (isNil(arr) || !arr.length) {
    return 0;
  }
  return last(arr)?.index ?? 0;
};

const getServicesCount = (services: Services) => {
  const { knownRepairs, diagnoses } = services;
  return (
    Math.max(getLastIndexSafely(knownRepairs), getLastIndexSafely(diagnoses)) +
    1
  );
};

const getIndexPropSafely = (
  arr: { index: number }[],
  index: number,
  services: Services
) => {
  const existingIndex = arr && arr[index] && arr[index].index;
  return {
    index: isNil(existingIndex) ? getServicesCount(services) : existingIndex,
  };
};

const addIndex = (
  arrayOfItems: any[],
  arrayToGetIndex: any[],
  services: Services
) => {
  return (arrayOfItems || []).map((item: any, index: number) => ({
    ...item,
    ...getIndexPropSafely(arrayToGetIndex, index, services),
  }));
};

const reducer = handleActions<PreliminaryRepairStoreState, any>(
  {
    [setStartPath?.type]: createSimpleHandler('startPath'),
    [setDeclineRepairs?.type]: createSimpleHandler('declineRepairs'),
    [clearRequestInfo?.type]: () => ({ ...defaultState }),
    [clearDeclineRepairs?.type]: createClearHandler('declineRepairs', null),
    [setIsDataFromQuery?.type]: createSimpleHandler('isDataFromQuery'),
    [setCarSelectionAttr?.type]: (
      state,
      action: { payload: Partial<PreliminaryCar> }
    ) => ({
      ...state,
      car: { ...state.car, ...action.payload } as PreliminaryCar,
    }),
    [clearCarSelectionAttr?.type]: (state, action) => ({
      ...state,
      car: {
        ...state.car,
        [action.payload as keyof PreliminaryCar]: { value: null },
      } as PreliminaryCar,
    }),
    [resetCarSelection?.type]: createClearHandler('car', {
      ...defaultState.car,
    }),
    [setCarImage?.type]: (state, action) => ({
      ...state,
      car: { ...state.car, image: action.payload } as PreliminaryCar,
    }),
    [setDeliveryAddress?.type]: (state, action: { payload: Address }) => ({
      ...state,
      delivery: {
        ...state.delivery,
        address: action.payload,
      },
    }),
    [setArrivalInstructions?.type]: (state, action) => {
      return {
        ...state,
        delivery: {
          ...state.delivery,
          arrivalInstructions: action?.payload,
        },
      };
    },
    [setDeliveryCoordinates?.type]: (
      state,
      action: { payload: Coordinates }
    ) => ({
      ...state,
      delivery: {
        ...state.delivery,
        coordinates: action.payload,
      },
    }),
    [setShop?.type]: createSimpleHandler('shop'),
    [setZip?.type]: createSimpleHandler('zip'),
    [setVisit?.type]: createSimpleHandler('visit'),
    [setTimezone?.type]: createSimpleHandler('timezone'),
    [setCoordinates?.type]: createSimpleHandler('coordinates'),
    [clearServices?.type]: (state) => ({
      ...state,
      services: { ...defaultServices },
    }),
    [addKnownRepair?.type]: (
      state,
      action: { payload: PreliminaryKnowRepair }
    ) => {
      const { knownRepairs } = state.services;
      if (
        knownRepairs.find((repair) => action.payload.nodeId === repair.nodeId)
      ) {
        return state;
      }
      const newState = cloneDeep(state);
      newState.services.knownRepairs = [
        ...knownRepairs,
        { ...action.payload, index: getServicesCount(state.services) },
      ];
      return newState;
    },
    [removeKnownRepair?.type]: (
      state,
      action: { payload: { nodeId: number } }
    ) => ({
      ...state,
      services: {
        ...state.services,
        knownRepairs: state.services.knownRepairs.filter(
          (repair) => repair.nodeId !== action.payload.nodeId
        ),
      },
    }),
    [clearKnownRepairs?.type]: (state) => ({
      ...state,
      services: {
        ...state.services,
        knownRepairs: [],
      },
    }),
    [addDiagnosis?.type]: (state, action: { payload: Diagnose }) => {
      // DUPLICATE CHECK
      let foundDiagnosis = false;
      const actionNodes = action.payload.traverseNodes
        .map((node) => node.nodeId)
        .sort();

      state.services.diagnoses.forEach((diag) => {
        const diagNodes = diag.traverseNodes.map((node) => node.nodeId).sort();
        if (JSON.stringify(diagNodes) === JSON.stringify(actionNodes)) {
          foundDiagnosis = true;
        }
      });
      if (foundDiagnosis) {
        return state;
      }

      const newState = cloneDeep(state);
      newState.services.diagnoses = [
        ...newState.services.diagnoses,
        { ...action.payload, index: getServicesCount(state.services) },
      ];

      return newState;
    },

    [clearDiagnoses?.type]: createClearHandler('services.diagnoses', []),
    [addCustomServiceRequest?.type]: (state, action: { payload: string }) => {
      if (!state.services.customRequests.includes(action.payload)) {
        return {
          ...state,
          services: {
            ...state.services,
            customRequests: [...state.services.customRequests, action.payload],
          },
        };
      }
      return state;
    },
    [removeCustomServiceRequest?.type]: (
      state,
      action: { payload: CustomRequest }
    ) => ({
      ...state,
      services: {
        ...state.services,
        customRequests: state.services.customRequests.filter(
          (serviceRequest) => serviceRequest !== action.payload.message
        ),
      },
    }),
    [updateServicesQuote?.type]: (
      state,
      action: {
        payload: {
          services: {
            knownRepairs: KnowRepair[];
            diagnoses: Diagnose[];
            customRequests: CustomRequest[];
          };
          totalCost: number;
        };
      }
    ) => {
      const { payload } = action;
      const diagnoses = payload.services.diagnoses.map(
        (diagnosis: any, index: number) => ({
          ...diagnosis,
          ...getIndexPropSafely(
            state.services.diagnoses,
            index,
            state.services
          ),
          traverseNodes: diagnosis.symptoms.map((symptom: any) => ({
            nodeId: symptom.symptomId,
            message: null,
          })),
        })
      );
      const customRequests = payload.services.customRequests.map(
        (customRequest) => customRequest.message
      );
      const knownRepairs: PreliminaryKnowRepair[] =
        payload.services.knownRepairs.map((knownRepair) => ({
          nodeId: knownRepair.nodeId,
          name: knownRepair.name,
        }));

      return {
        ...state,
        services: {
          ...state.services,
          knownRepairs,
          diagnoses,
          customRequests,
        },
      };
    },
    [updateServices?.type]: (
      state,
      {
        payload,
      }: {
        payload: {
          knownRepairs: KnowRepair[];
          diagnoses: Diagnose[];
          customRequests: CustomRequest[];
        };
      }
    ) => {
      const diagnoses = payload.diagnoses.map(
        (diagnosis: any, index: number) => ({
          ...diagnosis,
          ...getIndexPropSafely(
            state.services.diagnoses,
            index,
            state.services
          ),
          traverseNodes: diagnosis.symptoms.map((symptom: any) => ({
            nodeId: symptom.symptomId,
            message: null,
          })),
        })
      );

      const customRequests = payload.customRequests.map(
        (customRequest) => customRequest.message
      );

      const knownRepairs: PreliminaryKnowRepair[] = payload.knownRepairs.map(
        (knownRepair) => ({
          nodeId: knownRepair.nodeId,
          name: knownRepair.name,
        })
      );

      return {
        ...state,
        services: {
          ...state.services,
          knownRepairs,
          diagnoses,
          customRequests,
        },
      };
    },
    [getPrices?.typeAPISuccess]: (
      state,
      action: { payload: GetMotorPricingPayload }
    ) => {
      const { car, requestMetadata, ...services } = action.payload;
      const { residualValue } = car;

      if (state.car) {
        return {
          ...state,
          responsiblePartyId: state.responsiblePartyId,
          car: {
            ...state.car,
            residualValue,
          },
          requestMetadata: {
            ...state.requestMetadata,
            ...requestMetadata,
          },
          services: {
            ...state.services,
            ...services,
            knownRepairs: addIndex(
              services.knownRepairs,
              state.services.knownRepairs,
              state.services
            ),
            diagnoses: addIndex(
              services.diagnoses,
              state.services.diagnoses,
              state.services
            ),
            customRequests: state.services?.customRequests ?? [],
          },
        };
      }
      return state;
    },
    [setHoursToFirstAvailableAppointment?.type]: createSimpleHandler(
      'delivery.hoursToFirstAvailableAppointment'
    ),
    [setNote?.type]: createSimpleHandler('note'),
    [saveNote?.typeAPISuccess]: createSimpleHandler('note'),
    [getNote?.typeAPISuccess]: (state, action) => {
      const reversedPayload = action.payload.content.reverse();
      const firstConsumerNote = reversedPayload.find(
        (note: Note) => note.origin === 'CONSUMER'
      );
      return {
        ...state,
        note: firstConsumerNote,
      };
    },
    [updateNote?.typeAPISuccess]: createSimpleHandler('note'),
    [deleteNote?.typeAPISuccess]: createClearHandler('note'),
    [saveBookingDataForLater?.type]: createSimpleHandler('savedBookingData'),
    [setCarDecodeError?.type]: (state, action: { payload: number }) => {
      return {
        ...state,
        car: {
          ...state.car,
          error: action.payload,
        } as PreliminaryCar,
      };
    },
    [setRepairRequestError?.type]: createSimpleHandler('repairRequestError'),
    [throwAutoBookShownEvent?.typeAPISuccess]: (state) => {
      return {
        ...state,
        thrownAutoBookShownEvent: true,
      };
    },
    [setThrowAutoBookShownEvent?.type]: (state, action) => {
      return {
        ...state,
        thrownAutoBookShownEvent: action.payload,
      };
    },
    [setDeliveryAddressIsCustom?.type]: (state, action) => {
      return {
        ...state,
        delivery: {
          ...state.delivery,
          addressIsCustom: action.payload,
        },
      };
    },
    [setRequestCredit?.type]: createSimpleHandler('credit'),
    [saveAppointmentPreference?.type]: createSimpleHandler(
      'appointmentPreference'
    ),
    [saveBillingAddress?.type]: createSimpleHandler('billingAddress'),
    [saveResponsiblePartyId?.type]: createSimpleHandler('responsiblePartyId'),
    [signOut?.type]: () => ({ ...defaultState }),
  },
  defaultState
);

export default reducer;
