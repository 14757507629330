import { handleActions } from 'redux-actions';
import {
  clearFleetAppointments,
  getFleetAppointments,
  getFleetSummary,
  setFleetAppointmentsEmpty,
  getFleetMaintenance,
  getFleetCarCount,
  setFleetCarFilter,
  clearFleetMaintenance,
  getFleetRecommended,
  clearFleetRecommended,
  setFleetRecommended,
  getVisitSlots,
  getWorkOrderVisit,
  getZendeskTicketByTaskType,
  clearFleetInvoices,
  getFleetCanceled,
  getVisit,
  getFleetVisitsByMonth,
  getFleetInvoices,
  getFleetQuotes,
  getVisitsForSelectionPage,
  setSelectedInvoices,
  setFleetVehicleDetailsTab,
  setFleetVehicleDetailsAppointmentsTab,
  getFleetCompliance,
  getFleetComplianceCounts,
  getFleetInfo,
} from 'store/fleet/actions';
import { signOut } from 'store/auth/actions';
import { createAPIPaginationHandler } from 'store/utils/createAPIPaginationHandler';
import { createClearHandler } from 'store/utils/createClearHandler';
import { createSimpleHandler } from 'store/utils/createSimpleHandler';
import { IFleetCanceled } from 'entities/IFleetCanceled';
import { Visit } from 'entities/Visit';
import { FleetInfo } from 'entities/FleetInfo';

export interface FleetState {
  fleetInfo?: FleetInfo;
  appointments: any;
  summary: any;
  maintenance: any;
  canceled?: { data: IFleetCanceled[]; pagination: any };
  compliance: any;
  recommended: any;
  carCount: any;
  complianceCarCount: any;
  carFilter: any;
  visitSlots: any;
  visit?: Visit;
  visitsByMonth: Visit[];
  invoices: any;
  quotes: any;
  vehicleDetailsTab: number;
  visits: any | Visit[];
  selectedInvoices: any[];
  vehicleDetailsAppointmentsTab: string;
  workOrderVisits?: any[];
  zendeskTickets?: any[];
}

const defaultState: FleetState = {
  fleetInfo: undefined,
  appointments: undefined,
  summary: undefined,
  maintenance: undefined,
  canceled: undefined,
  compliance: undefined,
  recommended: undefined,
  carCount: undefined,
  complianceCarCount: undefined,
  carFilter: undefined,
  visitSlots: undefined,
  visit: undefined,
  visitsByMonth: [],
  invoices: undefined,
  quotes: undefined,
  visits: [],
  selectedInvoices: [],
  vehicleDetailsTab: 0,
  vehicleDetailsAppointmentsTab: 'upcoming',
  zendeskTickets: [],
};

const reducer = handleActions<any>(
  {
    [getFleetAppointments.typeAPISuccess]: createAPIPaginationHandler(
      'appointments',
      'content'
    ),
    [getFleetInvoices.typeAPISuccess]: createAPIPaginationHandler(
      'invoices',
      'content'
    ),
    [getFleetCanceled.typeAPISuccess]: createAPIPaginationHandler(
      'canceled',
      'content'
    ),
    [getFleetCompliance.typeAPISuccess]: createAPIPaginationHandler(
      'compliance',
      'content'
    ),
    [clearFleetAppointments.type]: createClearHandler('appointments'),
    [clearFleetInvoices.type]: createClearHandler('invoices'),
    [setFleetAppointmentsEmpty.type]: () => ({
      appointments: { data: [], pagination: { totalElements: 0 } },
    }),
    [getFleetSummary.typeAPISuccess]: createSimpleHandler('summary'),
    [getFleetMaintenance.typeAPISuccess]: createAPIPaginationHandler(
      'maintenance',
      'content'
    ),
    [clearFleetMaintenance.type]: createClearHandler('maintenance'),
    [getFleetRecommended.typeAPISuccess]: createAPIPaginationHandler(
      'recommended',
      'content'
    ),
    [getFleetCarCount.typeAPISuccess]: createSimpleHandler('carCount'),
    [getFleetComplianceCounts.typeAPISuccess]:
      createSimpleHandler('complianceCarCount'),
    [setFleetCarFilter.type]: createSimpleHandler('carFilter'),
    [clearFleetRecommended.type]: () => createClearHandler('recommended'),
    [setFleetRecommended.type]: (state, action) => ({
      ...state,
      recommended: action.payload,
    }),
    [getVisitSlots.typeAPISuccess]: createSimpleHandler('visitSlots'),
    [getWorkOrderVisit.typeAPISuccess]: createSimpleHandler('workOrderVisits'),
    [getVisit.typeAPISuccess]: createSimpleHandler('visit'),
    [getFleetVisitsByMonth.typeAPISuccess]:
      createSimpleHandler('visitsByMonth'),
    [getVisitsForSelectionPage.typeAPISuccess]: createAPIPaginationHandler(
      'visits',
      'content'
    ),
    [setSelectedInvoices.type]: createSimpleHandler('selectedInvoices'),
    [getFleetQuotes.typeAPISuccess]: createAPIPaginationHandler(
      'quotes',
      'content'
    ),
    [setFleetVehicleDetailsTab.type]: createSimpleHandler('vehicleDetailsTab'),
    [setFleetVehicleDetailsAppointmentsTab.type]: createSimpleHandler(
      'vehicleDetailsAppointmentsTab'
    ),
    [getFleetInfo.typeAPISuccess]: createSimpleHandler('fleetInfo'),
    [getZendeskTicketByTaskType.typeAPISuccess]:
      createSimpleHandler('zendeskTickets'),
    [signOut?.type]: () => ({ ...defaultState }),
  },
  defaultState
);

export default reducer;
