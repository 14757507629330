import { createSelector } from 'reselect';

import moment from 'moment';
import { RootStoreState } from '../reducers';
import { FleetState } from './reducer';

const getFleet = (state: RootStoreState) => state.fleet as FleetState;

export const selectFleetInfo = createSelector(
  getFleet,
  (fleet) => fleet.fleetInfo
);

export const selectFleetResponsibleParties = createSelector(
  selectFleetInfo,
  (fleetInfo) => fleetInfo?.fleetResponsibleParties
);

export const selectFleetAppointments = createSelector(
  getFleet,
  (fleet) => fleet.appointments?.data
);

export const selectFleetAppointmentsPagination = createSelector(
  getFleet,
  (fleet) => fleet.appointments?.pagination
);

export const selectUpcomingAppointmentsCount = createSelector(
  getFleet,
  (fleet) => fleet.appointments?.pagination.totalElements
);

export const selectFleetInvoices = createSelector(
  getFleet,
  (fleet) => fleet.invoices?.data
);

export const selectFleetInvoicesPagination = createSelector(
  getFleet,
  (fleet) => fleet.invoices?.pagination
);

export const selectFleetInvoicesCount = createSelector(
  getFleet,
  (fleet) => fleet.invoices?.pagination.totalElements
);

export const selectFleetSummary = createSelector(
  getFleet,
  (fleet) => fleet.summary
);

export const selectFleetMaintenance = createSelector(
  getFleet,
  (fleet) => fleet.maintenance?.data
);

export const selectFleetMaintenancePagination = createSelector(
  getFleet,
  (fleet) => fleet.maintenance?.pagination
);

export const selectMaintenanceCount = createSelector(
  getFleet,
  (fleet) => fleet.maintenance?.pagination?.totalElements
);

export const selectFleetCanceled = createSelector(
  getFleet,
  (fleet) => fleet.canceled?.data
);

export const selectFleetCanceledPagination = createSelector(
  getFleet,
  (fleet) => fleet.canceled?.pagination
);

export const selectFleetCanceledCount = createSelector(
  getFleet,
  (fleet) => fleet.canceled?.pagination?.totalElements
);

export const selectFleetRecommended = createSelector(
  getFleet,
  (fleet) => fleet.recommended?.data
);

export const selectFleetRecommendedPagination = createSelector(
  getFleet,
  (fleet) => fleet.recommended?.pagination
);

export const selectFleetCarCount = createSelector(
  getFleet,
  (fleet) => fleet.carCount
);

export const selectFleetCarFilter = createSelector(
  getFleet,
  (fleet) => fleet.carFilter
);

export const selectVisitSlots = createSelector(
  getFleet,
  (fleet) => fleet?.visitSlots
);

export const selectWorkOrderVisits = createSelector(
  getFleet,
  (fleet) => fleet?.workOrderVisits
);

export const selectFleetVisitsByMonth = createSelector(
  getFleet,
  (fleet) => fleet?.visitsByMonth
);

export const selectFleetQuotesData = createSelector(
  getFleet,
  (fleet) => fleet?.quotes?.data
);

export const selectFleetQuotesPagination = createSelector(
  getFleet,
  (fleet) => fleet?.quotes?.pagination
);

export const selectFleetVisitsDates = createSelector(
  selectFleetVisitsByMonth,
  (visits) => {
    const result = visits.reduce((dates: any[], item: any) => {
      const newDates = [...dates];
      const date = +moment(item.actualStartLocal).format('D');

      const dateIndex = newDates.findIndex((d) => d.date === date);

      if (dateIndex >= 0) {
        newDates[dateIndex] = { date, count: newDates[dateIndex].count + 1 };
      } else {
        newDates.push({ date, count: 1 });
      }
      return newDates;
    }, []);

    return result;
  }
);

export const selectFleetVehicleDetailsTab = createSelector(
  getFleet,
  (fleet) => fleet?.vehicleDetailsTab
);

export const selectFleetVehicleDetailsAppointmentsTab = createSelector(
  getFleet,
  (fleet) => fleet?.vehicleDetailsAppointmentsTab
);

export const selectFleetVisitsPagination = createSelector(
  getFleet,
  (fleet) => fleet?.visits?.pagination
);

export const selectFleetVisitsData = createSelector(
  getFleet,
  (fleet) => fleet?.visits?.data
);

export const selectFleetVisit = createSelector(
  getFleet,
  (fleet) => fleet?.visit
);

export const selectSelectedInvoices = createSelector(
  getFleet,
  (fleet) => fleet?.selectedInvoices
);

export const selectFleetCompliance = createSelector(
  getFleet,
  (fleet) => fleet?.compliance?.data
);

export const selectFleetComplianceServiceCounts = createSelector(
  getFleet,
  (fleet) => fleet?.complianceCarCount
);

export const selectFleetCompliancePagination = createSelector(
  getFleet,
  (fleet) => fleet?.compliance?.pagination
);

export const selectFleetZendeskTickets = createSelector(
  getFleet,
  (fleet) => fleet?.zendeskTickets || []
);
