export enum MicrosoftService {
  Autosuggest = 'Autosuggest',
  Locations = 'Locations',
  TimeZone = 'TimeZone',
  Routes = 'Routes',
}

export type MicrosoftResponse<T> = {
  statusCode: number;
  resourceSets: Array<{ resources: Array<T> }>;
  errorDetails?: string[];
};

export type LocationResponse = {
  address: {
    addressLine: string;
    locality: string;
    adminDistrict: string;
    postalCode: string;
    countryRegion: string;
  };
  bbox: [number, number, number, number];
  point: { type: 'Point'; coordinates: [number, number] };
};

type AutoSuggestBaseAddress = {
  addressLine: string;
  adminDistrict: string;
  adminDistrict2: string;
  countryRegion: string;
  countryRegionIso2: string;
  formattedAddress: string;
  locality: string;
  neighborhood: string;
  postalCode: string;
};

type AutoSuggestAddress = {
  __type: 'Address';
  address: AutoSuggestBaseAddress & { houseNumber: string; streetName: string };
};

type AutoSuggestPlace = {
  __type: 'Place';
  address: AutoSuggestBaseAddress & {};
};

type AutoSuggestLocalBusiness = {
  __type: 'LocalBusiness';
  address: AutoSuggestBaseAddress & {};
  name: string;
};

export type AutosuggestResponse = {
  value: Array<
    AutoSuggestAddress | AutoSuggestPlace | AutoSuggestLocalBusiness
  >;
};

export type TimeZoneResponse = {
  timeZone: { ianaTimeZoneId: string };
};
