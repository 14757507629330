import { Car } from 'entities/Car';

export const mapCarResponsibleParties = (
  car: Car,
  fleetCarRps: Record<string, string>
) =>
  car.fleetResponsibleParties
    ?.filter((rp) => fleetCarRps[rp.responsiblePartyId])
    .map((rp: any) => {
      return {
        ...rp,
        responsiblePartyName: fleetCarRps[rp.responsiblePartyId],
      };
    });
