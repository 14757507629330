import { WorkAuthorization } from './AppointmentData';
import { Price } from './Price';
import { ResponsibleParty } from './ResponsibleParty';

export type KnowRepair = {
  id: number;
  nodeId: number;
  name: string;
  repairDone: boolean;
  price: Price[];
  modified: string;
  modifiedBy: string;
};

export type PreliminaryKnowRepair = {
  nodeId: number;
  name: string;

  // todo: figure out why we need message, looks like it is always null
  message?: string | null;

  dueDate?: string;
  responsiblePartyId?: string | null;

  // message comes from search repairs,
};

export type KnowRepairWithPrice = {
  id: number | null;
  nodeId: number;
  name: string;
  price: Price[];
  index: number;
  sublet?: boolean;
  inspectionFee?: number | null;
  workAuthorization?: WorkAuthorization;
  workOrderItemResponsibleParties?: ResponsibleParty[];
  responsiblePartyId?: string | null;
};

export function isKnowRepairWithPrice(
  knowRepair: PreliminaryKnowRepair | KnowRepairWithPrice | KnowRepair
): knowRepair is KnowRepairWithPrice {
  if ((knowRepair as KnowRepairWithPrice).price) {
    return true;
  }
  return false;
}
