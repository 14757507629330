import { createAction } from 'store/utils/createAction';
import { createAPIAction } from 'store/utils/createAPIAction';
import queryString from 'query-string';

import { setShop } from 'store/preliminaryRepair/actions';
import { getPrices } from 'store/repairs/actions';

import {
  RADIUS,
  START_GARAGE_SCHEDULE_SERVICE,
} from 'store/preliminaryRepair/constants';
import { Shop } from 'entities/Shop';
import { PreliminaryCar } from 'entities/PreliminaryCar';
import { ThunkDispatch } from 'redux-thunk';
import {
  geocodeFromAddress,
  getCoordinates,
  getTimezone,
} from 'utilities/bingmapsClient';

import {
  GetGeodataParams,
  GetTimezoneByCoordinatesParams,
  Shops,
  ShopWithGeoData,
  GetShopParams,
} from './types';

const serviceName = 'shops';

export const clearShops = createAction(`${serviceName}/CLEAR_SHOPS`);

export const setShops = createAction(`${serviceName}/SET_SHOPS`);

export const setGoogleMapsError = createAction(
  `${serviceName}/GOOGLE_MAPS_ERROR`
);

export const getShopsByLatLong = createAPIAction(
  `${serviceName}/GET_SHOPS_BY_LAT_LONG_REQUEST`,
  ({ query, coordinates = [0, 0], repairIds, delivery, car, zip }) => {
    const q = {
      radius: RADIUS,
      longitude: coordinates[0],
      latitude: coordinates[1],
      year: car?.year?.value ?? undefined,
      make: car?.make?.value ?? undefined,
      repairFocus: repairIds || null,
      delivery,
      size: query?.size ?? 10,
      page: query?.pageNumber ?? 0,
      sort: 'tier, distance',
      zipCode: zip,
    };

    return {
      endpoint: `/isp-service/shops?${queryString.stringify(q, {
        arrayFormat: 'comma',
      })}`,
    };
  }
);

export const getShop = createAPIAction(`${serviceName}/GET_SHOP`, ({ id }) => ({
  endpoint: `/isp-service/isps/${id}`,
}));

export const setShopDetails = createAction(`${serviceName}/SET_SHOP_DETAILS`);

export const getGeodataByZip =
  (data: GetGeodataParams) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    const { zip } = data;
    let geodata;
    try {
      geodata = await geocodeFromAddress(zip);
    } catch (error) {
      dispatch(setGoogleMapsError(error));
    }

    return geodata;
  };

export const getTimezoneByCoordinates = async (
  data: GetTimezoneByCoordinatesParams
) => {
  const { longitude, latitude } = data;
  const tz = await getTimezone(latitude, longitude);

  return tz;
};

export const getShopsByZip =
  (data: GetShopParams) => async (dispatch: ThunkDispatch<any, any, any>) => {
    const { zip } = data;
    let { geodata } = data;

    if (!geodata) {
      try {
        geodata = await geocodeFromAddress(zip);
      } catch (error) {
        dispatch(setGoogleMapsError(error));
        return;
      }
    }

    const coordinates: [number, number] = getCoordinates(geodata);

    const shopsResponse = await dispatch(
      getShopsByLatLong({ ...data, coordinates })
    );

    const shopsData = shopsResponse?.payload ?? {
      referral: null,
      delivery: null,
    };

    const addGeoData = (shop: Shop) => {
      const newShop: ShopWithGeoData = { ...shop };
      newShop.center = coordinates;
      newShop.zip = zip as string;

      if (geodata.results[0]) {
        const { formatted_address = '' } = geodata.results[0]; // eslint-disable-line

        const placeArray = formatted_address.split(','); // eslint-disable-line
        const cityAndZip = `${placeArray[0]} ${placeArray[1]}`;

        newShop.placename = cityAndZip;
      }
      return newShop;
    };

    const shops: Shops = { referral: null, delivery: null };
    if (shopsData.delivery) {
      shops.delivery = addGeoData(shopsData.delivery);
    }
    dispatch(setShopDetails(shops.delivery));
    return dispatch(setShops(shops));
  };

export const getClosestShopWithRepairPrices =
  (data: GetShopParams) => async (dispatch: ThunkDispatch<any, any, any>) => {
    const shopRequestFunction = data.coordinates
      ? getShopsByLatLong
      : getShopsByZip;

    const shopsResponse: any = await dispatch(shopRequestFunction(data));

    const shops: any =
      shopsResponse?.payload?.content || (shopsResponse?.payload ?? []);

    // REMOVE REFERRAL If THE USER HAS ALREADY CHOSEN DELIVERY FROM THE GARAGE PAGE START
    if (data.startPath === START_GARAGE_SCHEDULE_SERVICE) {
      delete shops.referral;
    }

    const prices = await dispatch(
      getPrices({
        ispIds: shops.delivery ? [shops.delivery.id] : [],
        car: data.car as PreliminaryCar,
        services: data.services,
        consumerId: data.consumerId,
        quoteId: data.quoteId,
        fleetId: data.fleetId,
        zip: data.zip as string,
      })
    );

    if (shops.delivery) {
      dispatch(setShop(shops.delivery));
    }

    return prices;
  };

export const getLiveDeliverySchedule = createAPIAction(
  `${serviceName}/GET_LIVE_DELIVERY_SCHEDULE`,
  (data) => ({
    endpoint: `/isp-service/scheduling/availability?${queryString.stringify(
      data
    )}`,
  })
);

export const clearLiveDeliverySchedule = createAction(
  `${serviceName}/CLEAR_LIVE_DELIVERY_SCHEDULE`
);

export const activeServiceAreaExists = createAPIAction(
  `${serviceName}/SERVICE_AREA_BY_ZIP_EXISTS`,
  (zipCode) => ({
    endpoint: `/isp-service/service-areas?${queryString.stringify(zipCode)}`,
    method: 'HEAD',
  })
);
