import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import FleetContactSupport from './components/common/FleetLayout/components/FleetContactSupport';
import { setCurrentFunnel } from './store/funnel/actions';
import { affirmScript } from './utilities/affirmScript';
import { getRandomNonce } from './utilities/crypto';
import loadScript from './utilities/loadScript';

const {
  AFFIRM_API_KEY,
  AFFIRM_ENABLED,
  AFFIRM_API_URL,
  ZENDESK_CHAT_ENABLED,
  ZENDESK_FLEET_CHAT_ENABLED,
  ZENDESK_CHAT_API_KEY,
  GOOGLE_TAG_MANAGER_AUTH,
  GOOGLE_TAG_MANAGER_PREVIEW,
  GOOGLE_TAG_CONTAINER_ID,
  BING_MAPS_API_KEY,
} = window.RepairSmith;
const affirmEnabled = AFFIRM_ENABLED === 'true';
const zendeskChatEnabled = ZENDESK_CHAT_ENABLED === 'true';
const zendeskFleetChatEnabled = ZENDESK_FLEET_CHAT_ENABLED === 'true';
const googleTagContainerId = GOOGLE_TAG_CONTAINER_ID;
const googleTagManagerAuth = GOOGLE_TAG_MANAGER_AUTH;
const googleTagManagerPreview = GOOGLE_TAG_MANAGER_PREVIEW;

type DocumentProps = {
  children: React.ReactNode;
};

const Document = ({ children }: DocumentProps) => {
  const cspNonce = getRandomNonce();
  const dispatch = useDispatch();

  useEffect(() => {
    if (affirmEnabled) {
      affirmScript(AFFIRM_API_KEY, AFFIRM_API_URL, cspNonce);
    }

    window.setTimeout(() => {
      loadScript(
        '//solutions.invocacdn.com/js/invoca-latest.min.js',
        document.querySelector('head') as HTMLHeadElement,
        'invoca',
        ['nonce', cspNonce]
      );
    }, 1000);
  }, [cspNonce]);

  const savedCustomFunnel = sessionStorage.getItem('partnerFunnel');
  if (savedCustomFunnel) {
    dispatch(setCurrentFunnel(JSON.parse(savedCustomFunnel)));
  }

  window.zESettings = {
    webWidget: {
      chat: {
        suppress: true,
        departments: {
          enabled: [],
        },
      },
      contactForm: {
        suppress: true,
      },
      helpCenter: {
        suppress: true,
      },
      talk: {
        suppress: true,
      },
      answerBot: {
        suppress: true,
      },
    },
  };

  /* OneTrust Cookies Consent Notice start for autonationmobileservice.com */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const OptanonWrapper = () => {};

  window.InvocaTagId = '1649/4209653272';

  const domains: any = {
    'googleads.g.doubleclick.net': {
      domain: 'googleads.g.doubleclick.net',
      connect: true,
    },
    'pagead2.googlesyndication.com': {
      domain: 'pagead2.googlesyndication.com',
      connect: true,
    },

    // PAYMENT FINANCING OPTIONS
    'affirm': {
      domain: '*.affirm.com',
      connect: true,
      frame: true,
    },
    // WEB ANALYTICS
    'amplitude': {
      domain: '*.amplitude.com',
      connect: true,
    },
    // CDN FOR HOSTING NORMALIZE.CSS
    'cloudflare': {
      domain: 'cdnjs.cloudflare.com',
      style: true,
    },
    'cloudfront': {
      domain: '*.cloudfront.net',
      media: true,
      style: true,
    },
    // CONTENT CMS
    'contentful': {
      domain: '*.contentful.com',
      connect: true,
    },
    'cookieLaw': {
      domain: 'cdn.cookielaw.org',
      connect: true,
    },
    'dataDog': {
      domain: 'browser-intake-datadoghq.com',
      connect: true,
    },
    'dataDogHttp': {
      domain: '*.datadoghq.com',
      connect: true,
    },
    // GTM/GA
    'doubleClick': {
      domain: '*.doubleclick.net',
      frame: true,
      connect: true,
    },
    'ga': {
      domain: 'www.google-analytics.com',
      connect: true,
    },
    'googleAnalytics': {
      domain: 'analytics.google.com',
      connect: true,
    },
    'googleAll': {
      domain: '*.google.com',
      connect: true,
    },
    'google': {
      domain: 'google.com',
      connect: true,
    },
    'googleadservices': {
      domain: 'www.googleadservices.com',
      connect: true,
    },
    // FOR MAPS AND FONTS
    'googleapis': {
      domain: '*.googleapis.com',
      connect: true,
      style: true,
    },
    'gtm': {
      domain: 'www.googletagmanager.com',
      connect: true,
      frame: true,
    },
    'hotjarIo': {
      domain: '*.hotjar.io',
      connect: true,
    },
    // DYNAMIC SUPPORT PHONE NUMBER
    'invocaCdn': {
      domain: '*.invocacdn.com',
      connect: true,
    },
    'invocaNet': {
      domain: '*.invoca.net',
      connect: true,
    },
    'honeybadger': {
      domain: 'api.honeybadger.io',
      connect: true,
    },

    // VOUCHERIFY - COUPON CODES AND PROMOTIONS
    'voucherify': {
      domain: 'api.voucherify.io',
      connect: true,
    },
    'oneTrust': {
      domain: '*.onetrust.com',
      connect: true,
    },
    // SEGMENT - WEB ANALYTICS/TRACKING
    'segment': {
      domain: 'cdn.segment.com api.segment.io',
      connect: true,
    },
    // CREDIT CARD PAYMENTS
    'spreedly': {
      domain: '*.spreedly.com',
      frame: true,
    },
    'virtualEarth': {
      domain: 'dev.virtualearth.net',
      connect: true,
    },
    // A/B TESTING -- THIS IS THE ONLY UNSAFE ENTRY BECAUSE WE WANT TO ALLOW VWO TO PERFORM AB TESTING, WHICH INJECTS SCRIPTS
    'vwoUnsafe': {
      domain:
        "'unsafe-inline' 'unsafe-eval' *.visualwebsiteoptimizer.com app.vwo.com",
      script: true,
      connect: true,
      style: true,
    },
    // ZENDESK CHAT
    'zendesk': {
      domain: "'unsafe-inline' 'unsafe-eval' *.zendesk.com",
      connect: true,
    },
    'zdassets': {
      domain: "'unsafe-inline' 'unsafe-eval' *.zdassets.com",
      connect: true,
      media: true,
    },
    'zopimHttps': {
      domain: "'unsafe-inline' 'unsafe-eval' https://*.zopim.com",
      connect: true,
    },
    'zopimWss': {
      domain: 'wss://widget-mediator.zopim.com',
      connect: true,
    },
    'bing': {
      domain: '*.bing.com',
      connect: true,
      style: true,
    },
    'app.shop.pe': {
      domain: 'app.shop.pe',
      connect: true,
    },
    'shopper.shop.pe': {
      domain: 'shopper.shop.pe',
      connect: true,
    },
    'luckyorange.net': {
      domain: '*.luckyorange.net',
      connect: true,
    },
    'luckyorange.com': {
      domain: '*.luckyorange.com',
      connect: true,
    },
    'pubsub.googleapis.com': {
      domain: 'pubsub.googleapis.com',
      connect: true,
    },
    'visitors.live': {
      domain: 'wss://visitors.live',
      connect: true,
    },
    'all.visitors.live': {
      domain: 'wss://*.visitors.live',
      connect: true,
    },
    'tiktok': {
      domain: '*.tiktok.com',
      connect: true,
    },
    'talkable': {
      domain: 'www.talkable.com',
      frame: true,
    },
    'repairsmith': {
      domain: '*.repairsmith.local',
      connect: true,
    },
    'nytrng': {
      domain: 'nytrng.com',
      frame: true,
    },
    'facebook': {
      domain: 'www.facebook.com',
      frame: true,
    },
    'manage.safeopt.com': {
      domain: 'manage.safeopt.com',
      connect: true,
    },
    'repairsmith.w27s.net': {
      domain: 'repairsmith.w27s.net',
      connect: true,
    },
    'd.impct.site': {
      domain: 'd.impct.site',
      connect: true,
    },
  };
  const getSourceString = (key: string) =>
    Object.keys(domains)
      .filter((domainKey) => domains[domainKey][key])
      .map((domainKey) => domains[domainKey].domain)
      .join(' ');

  const scriptSrc = getSourceString('script');
  const connectSrc = getSourceString('connect');
  const styleSrc = getSourceString('style');
  const frameSrc = getSourceString('frame');
  const mediaSrc = getSourceString('media');

  return (
    <>
      <Helmet>
        {/* Google Tag Manager */}
        <script data-nonce={cspNonce} nonce={cspNonce}>
          {`(function(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.id = 'gtm-ams';
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl + '&gtm_auth=${googleTagManagerAuth}&gtm_preview=${googleTagManagerPreview}&gtm_cookies_win=x';
            var n = d.querySelector('[data-nonce]');
            if (n) {
              j.setAttribute('data-nonce', n.nonce || n.getAttribute('nonce'));
            }
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${googleTagContainerId}');`}
        </script>
        {/* End Google Tag Manager */}
        <meta
          httpEquiv="Content-Security-Policy"
          content={`default-src 'self'; base-uri 'none'; script-src 'nonce-${cspNonce}' 'strict-dynamic' 'self' ${scriptSrc}; style-src 'self' ${styleSrc}; img-src * 'self' data:; connect-src 'self' ${connectSrc}; font-src data: *; worker-src 'self' blob:; frame-src  ${frameSrc}; media-src ${mediaSrc}`}
        />
        <script
          async
          nonce={cspNonce}
          type="text/javascript"
          src="https://cdn.jsdelivr.net/gh/rspective/voucherify.js@latest/dist/voucherify.min.js"
        />
        <script
          async
          nonce={cspNonce}
          type="text/javascript"
          src="https://core.spreedly.com/iframe/iframe-v1.min.js"
        />
        <script
          async
          nonce={cspNonce}
          type="text/javascript"
          src={`https://www.bing.com/api/maps/mapcontrol?key=${BING_MAPS_API_KEY}`}
        />
        {zendeskChatEnabled && (
          <script
            async
            nonce={cspNonce}
            type="text/javascript"
            id="ze-snippet"
            src={`https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_CHAT_API_KEY}`}
          />
        )}
        {/* OneTrust Cookies Consent Notice start for autonationmobileservice.com */}
        {window.RepairSmith.APP_ENV !== 'local' && (
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            data-domain-script="638c4380-133c-4fbd-9683-9de8425229fa"
          />
        )}
      </Helmet>
      {children}

      {zendeskFleetChatEnabled && <FleetContactSupport />}
    </>
  );
};

export default Document;
