import { createAPIAction } from 'store/utils/createAPIAction';
import { createAction } from 'store/utils/createAction';
import { ThunkDispatch } from 'redux-thunk';
import { PreliminaryKnowRepair } from 'entities/KnowRepair';
import { Diagnose, PreliminaryDiagnose } from 'entities/Diagnose';
import {
  DeliveryChannel,
  InvoiceDocument,
  PreferredTime,
} from 'entities/RepairRequest';
import { RepairRequestShared } from 'entities/RepairRequestShared';
import { AddRepairRequestResponse } from 'store/preliminaryRepair/reducer';
import { EditRepairRequestResponse } from './reducer';
import { AddAppointmentParams } from '../../entities/Booking';

const serviceName = 'repairRequest';

export const getRepairRequestShared = createAPIAction<
  [{ id: string }],
  any,
  RepairRequestShared
>(`${serviceName}/GET_REPAIR_REQUEST_SHARED`, ({ id }) => ({
  endpoint: `/repair-service/consumer/repair-requests/${id}/share`,
}));

export const getAppointment = createAPIAction<[{ refNum: string }], any, any>(
  `${serviceName}/GET_APPOINTMENT`,
  ({ refNum }) => ({
    endpoint: `/repair-service/consumer/appointments/${refNum}`,
  })
);

export const getAppointmentNoStore = createAPIAction<
  [{ refNum: string }],
  any,
  any
>(`${serviceName}/GET_APPOINTMENT_NO_STORE`, ({ refNum }) => ({
  endpoint: `/repair-service/consumer/appointments/${refNum}`,
}));

export const setAppointment = createAction(`${serviceName}/SET_APPOINTMENT`);

type RescheduleAppointmentResponse = {
  id: string;
  repairRequestId: string;
  status: string;
  resolution: null;
  otherResolution: null;
  appointment: {
    id: string;
    status: string;
    resolution: null;
    otherResolution: null;
    deliveryChannel: DeliveryChannel;
    scheduleStart: null;
    consumerConfirmedAt: null;
    consumerScheduledAt: string;
    drivingDistanceMiles: number;
    drivingTimeSeconds: number;
    consumerConfirmedAppointment: boolean;
    ispContactInfo: {
      ispId: number;
      shopName: string;
      contactPersonName: string;
      phone: string;
      email: string;
      zip: string;
      address: string;
      latitude: number;
      longitude: number;
      city: string;
    };
    skeduloJobId: null;
    skeduloJobName: null;
    appointmentTime: null;
    appointmentDate: null;
  };
  deliveryChannel: 'NONE' | DeliveryChannel;
  appointmentTime: null;
  ispContactInfo: {
    ispId: number;
    shopName: string;
    contactPersonName: string;
    phone: string;
    email: string;
    zip: string;
    address: string;
    latitude: number;
    longitude: number;
    city: string;
  };
  smsRoNumber: null;
};

export const rescheduleAppointment = createAPIAction<
  [
    {
      repairOrderId: string;
      appointmentId: string;
      consumerPreferredTimes: string[];
      date: null;
      timeSlot: null;
      waitlistSelected?: boolean;
      resetParts?: boolean;
      vanId: string;
      autoBook: {};
    },
  ],
  any,
  RescheduleAppointmentResponse
>(`${serviceName}/RESCHEDULE_APPOINTMENT`, (data) => ({
  endpoint: `/repair-service/consumer/appointments/v2/${
    data.appointmentId
  }?resetParts=${!!data.resetParts}`,
  method: 'PATCH',
  data,
}));

export const cancelAppointment = createAPIAction<
  [
    {
      appointmentId: string;
      customerCancellationReason?: string;
      customerCancellationOtherReason?: string;
      resolutionId?: string;
    },
  ],
  any,
  {
    ispId: number;
    consumerId: number;
    repairOrderId: string;
    appointmentId: string;
    status: string;
    appointmentData: string | null;
    jobRef: string;
    drivingDistanceMiles: number;
    consumerPreferredTimes: PreferredTime[];
    skeduloJobId: any;
    skeduloJobName: any;
  }
>(
  `${serviceName}/CANCEL_APPOINTMENT`,
  ({
    appointmentId,
    customerCancellationReason,
    customerCancellationOtherReason,
    resolutionId,
  }) => ({
    endpoint: `/repair-service/consumer/appointments/v2/${appointmentId}${
      customerCancellationReason
        ? `?customerCancellationReason=${customerCancellationReason}`
        : ''
    }${
      customerCancellationOtherReason
        ? `&otherCancellationReason=${customerCancellationOtherReason}`
        : ''
    }${resolutionId ? `&resolutionId=${resolutionId}` : ''}`,
    method: 'PUT',
  })
);

export const clearCancelStatus = createAction<never>(
  `${serviceName}/CLEAR_CANCEL_APPOINTMENT_STATUS`
);

export const clearRescheduleStatus = createAction(
  `${serviceName}/CLEAR_RESCHEDULE_STATUS`
);

export const submitAppointmentRequest = createAPIAction<
  [AddAppointmentParams],
  any,
  AddRepairRequestResponse
>(`${serviceName}/ADD_APPOINTMENT`, (data) => {
  return {
    endpoint: `/repair-service/consumer/appointments/v2/${data.appointmentId}`,
    method: 'POST',
    data,
  };
});

export const updateAppointmentFAA = createAPIAction<
  [
    {
      appointmentId: string;
      availableDeliveryChannels: any[];
      hoursToFirstAvailableAppointment: number;
      consumerPreferredTimes: string[];
    },
  ],
  any,
  null
>(`${serviceName}/ADD_APPOINTMENT`, (data) => {
  return {
    endpoint: `/repair-service/consumer/appointments/v2/${data.appointmentId}`,
    method: 'POST',
    data,
  };
});

export const uploadInvoiceDocuments = createAPIAction<
  [{ workOrderId: string; files: File[] }],
  any,
  InvoiceDocument[]
>(`${serviceName}/UPLOAD_INVOICE`, ({ workOrderId, files }) => {
  const formData = new FormData();
  Array.from(files).forEach((file) => formData.append('documents', file));

  return {
    endpoint: `/repair-service/consumer/work-orders/${workOrderId}/upload-documents`,
    method: 'POST',
    data: formData,
  };
});

export const uploadInvoice =
  ({ workOrderId, files, refNum }: any) =>
  async (dispatch: any) => {
    await dispatch(uploadInvoiceDocuments({ workOrderId, files }));
    await dispatch(getAppointment({ refNum }));
  };

export const updateInvoiceDocuments = createAPIAction<
  [{ workOrderId: string; files: File[] }],
  any,
  InvoiceDocument[]
>(`${serviceName}/UPDATE_INVOICE`, ({ workOrderId, files }) => {
  const formData = new FormData();
  Array.from(files).forEach((file) => formData.append('documents', file));

  return {
    endpoint: `/repair-service/consumer/work-orders/${workOrderId}/upload-documents`,
    method: 'POST',
    data: formData,
  };
});

export const deleteInvoiceDocumentById = createAction<{ documentId: number }>(
  `${serviceName}/DELETE_INVOICE_DOCUMENT`
);
type DeleteInvoiceDocumentParams = {
  workOrderId: string;
  documentId: number;
};

const deleteInvoiceDocumentAPI = createAPIAction(
  `${serviceName}/DELETE_INVOICE`,
  (data: DeleteInvoiceDocumentParams) => ({
    endpoint: `/repair-service/consumer/work-orders/${data.workOrderId}/invoice/documents/${data.documentId}`,
    method: 'DELETE',
  })
);

export const deleteInvoiceDocument =
  (data: DeleteInvoiceDocumentParams) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    await dispatch(deleteInvoiceDocumentAPI(data));
    dispatch(deleteInvoiceDocumentById({ documentId: data.documentId }));
  };

export const submitKnownRepair = createAPIAction<
  [
    {
      refNum: string;
      knownRepair: PreliminaryKnowRepair;
      responsiblePartyId?: string;
    },
  ],
  any,
  EditRepairRequestResponse
>(
  `${serviceName}/SUBMIT_KNOWN_REPAIR`,
  ({ refNum, knownRepair, responsiblePartyId }) => ({
    endpoint: `/consumer-service/repair-request/${refNum}/services`,
    method: 'POST',
    data: { knownRepairs: [knownRepair], responsiblePartyId },
  })
);

export const submitCustomRequest = createAPIAction<
  [{ refNum: string; customRequest: string }],
  any,
  EditRepairRequestResponse
>(`${serviceName}/SUBMIT_CUSTOM_REQUEST`, ({ refNum, customRequest }) => ({
  endpoint: `/consumer-service/repair-request/${refNum}/services`,
  method: 'POST',
  data: { customRequests: [customRequest] },
}));

export const submitAllRepairs = createAPIAction<
  [
    {
      refNum: string;
      customRequests?: string[];
      knownRepairs?: PreliminaryKnowRepair[];
      diagnoses?: PreliminaryDiagnose[];
      responsiblePartyId?: string | null;
    },
  ],
  any,
  EditRepairRequestResponse
>(
  `${serviceName}/SUBMIT_ALL_REPAIRS`,
  ({
    refNum,
    customRequests,
    knownRepairs,
    diagnoses,
    responsiblePartyId,
  }) => ({
    endpoint: `/consumer-service/repair-request/${refNum}/services`,
    method: 'POST',
    data: { customRequests, knownRepairs, diagnoses, responsiblePartyId },
  })
);

export const submitDiagnosis = createAPIAction<
  [
    {
      refNum: string;
      diagnosis: PreliminaryDiagnose;
    },
  ],
  any,
  EditRepairRequestResponse
>(`${serviceName}/SUBMIT_DIAGNOSIS`, ({ refNum, diagnosis }) => ({
  endpoint: `/consumer-service/repair-request/${refNum}/services`,
  method: 'POST',
  data: { diagnoses: [diagnosis] },
}));

export const removeKnowRepairApiCall = createAPIAction<
  [
    {
      id: number;
      nodeId: number;
      repairId: string;
    },
  ],
  any,
  EditRepairRequestResponse
>(`${serviceName}/REMOVE_KNOWN_REPAIR`, ({ id, nodeId, repairId }) => ({
  endpoint: `/consumer-service/repair-request/${repairId}/services`,
  method: 'DELETE',
  data: { knownRepairs: [{ id, nodeId }] },
}));

export const removeCustomRequestApiCall = createAPIAction<
  [{ id: number; repairId: string }],
  any,
  EditRepairRequestResponse
>(`${serviceName}/REMOVE_CUSTOM_REPAIR`, ({ id, repairId }) => ({
  endpoint: `/consumer-service/repair-request/${repairId}/services`,
  method: 'DELETE',
  data: { customRequests: [{ id }] },
}));

export const removeDiagnosisApiCall = createAPIAction<
  [{ id: number; repairId: string }],
  any,
  EditRepairRequestResponse
>(`${serviceName}/REMOVE_DIAGNOSIS`, ({ id, repairId }) => ({
  endpoint: `/consumer-service/repair-request/${repairId}/services`,
  method: 'DELETE',
  data: { diagnoses: [{ id }] },
}));

export const saveChecklistAnswers = createAPIAction<
  [{ answers: any; repairId: string }],
  any,
  EditRepairRequestResponse
>(`${serviceName}/SAVE_CHECKLIST_ANSWERS`, ({ repairId, answers }) => ({
  endpoint: `/repair-service/consumer/repair-requests/checklist/${repairId}`,
  method: 'POST',
  data: answers,
}));

export const updateServices = createAction(`${serviceName}/UPDATE_SERVICES`);

export const removeKnownRepair =
  ({
    repairId,
    id,
    nodeId,
  }: {
    repairId: string;
    id: number;
    nodeId: number;
  }) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    const res = await dispatch(
      removeKnowRepairApiCall({ id, nodeId, repairId })
    );

    if (res.payload instanceof Error) {
      return null;
    }

    dispatch(
      getAppointment({
        refNum: repairId,
      })
    );

    return dispatch(
      updateServices({
        services: res.payload.diagnosisServices,
        totalCost: res.payload.totalCost,
      })
    );
  };

export const removeCustomRequest =
  ({ repairId, id }: { repairId: string; id: number }) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    const res = await dispatch(removeCustomRequestApiCall({ id, repairId }));

    dispatch(
      getAppointment({
        refNum: repairId,
      })
    );

    if (res.payload instanceof Error) {
      return null;
    }
    return dispatch(
      updateServices({
        services: res.payload.diagnosisServices,
        totalCost: res.payload.totalCost,
      })
    );
  };

export const clearRepairRequest = createAction(
  `${serviceName}/CLEAR_REPAIR_REQUEST`
);

export const getCancelResolutions = createAPIAction(
  `${serviceName}/GET_RESOLUTIONS`,
  () => ({
    endpoint: '/repair-service/consumer/appointments/resolutions',
    method: 'GET',
  })
);

export type MakeRepairRequestParams = {
  shopId: number | null;
  zip: string | null;
  timezone: string | null;
  services: {
    knownRepairs: PreliminaryKnowRepair[];
    diagnoses: Diagnose[];
    customRequests: string[];
  };
  carResidualValue?: string | null;
  carId?: number | null;
  quoteId?: string | null;
  fleetId?: string | null;
  funnelId?: string | null;
  firstSlotAvailable?: string | null;
  firstSlotDuration?: number | null;
  responsiblePartyId?: string | null;
};

export const makeRepairRequest = createAPIAction<
  [MakeRepairRequestParams],
  any,
  AddRepairRequestResponse
>(`${serviceName}/ADD_REPAIR`, (data) => ({
  endpoint: '/consumer-service/repair-request',
  method: 'POST',
  data,
}));

export const confirmWaitlistReschedule = createAPIAction<
  [{ appointmentId: string; availabilityWindowOfferedId: string }],
  any,
  null
>(
  `${serviceName}/CONFIRM_WAITLIST_RESCHEDULE`,
  ({ appointmentId, availabilityWindowOfferedId }) => ({
    endpoint: `/repair-service/consumer/appointments/v2/${appointmentId}/reschedule/${availabilityWindowOfferedId}`,
    method: 'PATCH',
  })
);

export const makePayment = createAPIAction(
  `${serviceName}/MAKE_PAYMENT`,
  (data) => {
    const { consumerId, workOrderId, ...rest } = data;

    return {
      endpoint: `/repair-service/consumer/${consumerId}/payment/work-orders/${workOrderId}/checkout`,
      method: 'POST',
      data: rest,
    };
  }
);

export const setRequestFunnel = createAction(
  `${serviceName}/SET_REQUEST_FUNNEL`
);

export const updateWorkOrderItemResponsibleParty = createAPIAction(
  `${serviceName}/UPDATE_WORK_ORDER_ITEM_RESPONSIBLE_PARTY`,
  (data: any[]) => ({
    endpoint: '/repair-service/consumer/work-order-item-responsible-parties',
    method: 'POST',
    data,
  })
);

export const setSelectedResponsibleParties = createAction(
  `${serviceName}/SET_SELECTED_RESPONSIBLE_PARTIES`
);

export const updateRequestCarDetails = createAPIAction(
  `${serviceName}/UPDATE_REQUEST_CAR_DETAILS`,
  (repairRequestId: string, mileage: number) => ({
    endpoint: `/repair-service/consumer/cars/mileage/${repairRequestId}`,
    method: 'PATCH',
    data: {
      mileage,
    },
  })
);
