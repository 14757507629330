import { RootStoreState } from 'store/reducers';
import { RSAA, RSAAAction, RSAACall } from 'redux-api-middleware';
import { isNil } from 'lodash';
import { selectAccessToken, selectRefreshToken } from 'store/auth/selectors';
import { setAuthCookies } from 'utilities/cookie';
import { setTokensFromCookie } from 'store/auth/actions';
import store from 'store/createStore';

const baseUrl = '/api';

/**
 * returns array of action names to use them as default types for RSAA
 */
export const getActionTypes = (
  actionName: string
): [string, string, string] => [
  `${actionName}/REQUEST`,
  `${actionName}/SUCCESS`,
  `${actionName}/FAIL`,
];

const getAccessHeader = (isFormData = false) => {
  const headers: Record<string, string> = {};

  if (!isFormData) {
    headers.Accept = 'application/json';
    headers['Content-Type'] = 'application/json';
  }

  return headers;
};

export const getDefaultFetch =
  (state: RootStoreState, signal: any) =>
  async (...fetchArgs: any[]) => {
    const [url, options = {}] = fetchArgs;
    if (signal) {
      options.signal = signal;
    }
    const accessToken = selectAccessToken(state);
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    };
    const res = await window.fetch(url, options);

    if (res && res.status === 401) {
      const refreshToken = selectRefreshToken(state);
      if (isNil(refreshToken)) {
        return res;
      }

      try {
        const rawResponse = await window.fetch(
          `${baseUrl}/consumer-service/refresh-token`,
          {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refreshToken }),
          }
        );
        const response = await rawResponse.json();

        // update Cookies and Redux if we have new tokens
        if (response) {
          setAuthCookies({ payload: response });
          store.dispatch(setTokensFromCookie(response));
          // updating options to send return request immediately
          options.headers = {
            ...options.headers,
            Authorization: `Bearer ${response.accessToken}`,
          };
        }

        const retryRes = await window.fetch(url, options);

        return retryRes;
      } catch (e) {
        return res;
      }
    }

    return res;
  };

export interface APIAction<Arguments extends any[], S, P, M> {
  (...args: Arguments): RSAAAction<S, P, M>;
  type: string;
  typeAPIRequest: string;
  typeAPISuccess: string;
  typeAPIFail: string;
}

interface RSAACallCustom extends RSAACall {
  data: Record<string, any> | FormData;
}

export interface SetupAction<T extends any[]> {
  (...args: T): Partial<RSAACallCustom>;
}

export const createAPIAction = <
  Arguments extends any[],
  S = any,
  P = any,
  M = any,
>(
  type: string,
  setupAction: SetupAction<Arguments>
): APIAction<Arguments, S, P, M> => {
  const actionCreator = (...args: Arguments) => {
    const { endpoint, method, data, fetch } = setupAction(...args);

    const signal = (data as any)?.signal ?? null;

    const state = store.getState();
    const defaultFetch = getDefaultFetch(state, signal);

    const action: RSAAAction<S, P, M> = {
      [RSAA]: {
        endpoint: `${baseUrl}${endpoint}`,
        method: method || 'GET',
        types: getActionTypes(type),
        headers: getAccessHeader(data instanceof window.FormData),
        fetch: defaultFetch,
      },
    };

    if (fetch) {
      action[RSAA].fetch = fetch;
    }

    if (data) {
      if (data instanceof FormData || typeof data === 'string') {
        action[RSAA].body = data;
      } else {
        const { signal, ...requestData } = data; // eslint-disable-line
        action[RSAA].body = JSON.stringify(
          Array.isArray(data) ? data : requestData
        );
      }
    }

    return action;
  };

  actionCreator.type = type;
  actionCreator.typeAPIRequest = `${type}/REQUEST`;
  actionCreator.typeAPISuccess = `${type}/SUCCESS`;
  actionCreator.typeAPIFail = `${type}/FAIL`;
  return actionCreator;
};
