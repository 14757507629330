import { useEffect } from 'react';
import cookie from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';
import { getCookie } from 'utilities/cookie';
import { RS_CONVERSION_INFO } from 'constants/cookies';
import { useLocation } from 'react-router-dom';
import { trackAnalyticsEvent } from 'store/global/actions';
import { datadogLogs } from '@datadog/browser-logs';
import { useDispatch } from 'react-redux';
import { addVwoExperimentViewed } from './store/consumer/actions';

const { DATA_DOG_CLIENT_API_KEY, APP_ENV } = window.RepairSmith;

interface AnalyticsWrapperProps {
  children: React.ReactNode;
}

const AnalyticsWrapper = ({
  children,
}: AnalyticsWrapperProps): React.ReactElement => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // READ TRACKING COOKIE AND SET IF NONE
    const conversionInfoCookie = getCookie(RS_CONVERSION_INFO);
    const conversionInfo = conversionInfoCookie
      ? JSON.parse(conversionInfoCookie)
      : {};
    const trackingId = conversionInfo?.trackingId;
    const newUUID = uuidv4();
    const qs = queryString.parse(window.location.search);

    // CREATE THE COOKIE OBJECT TO STORE
    const trackingParams: {
      trackingId: string;
      url: string;
      referrer: string;
      utmSource?: string | string[];
    } = {
      trackingId: trackingId || newUUID,
      url: window.location.href || document.documentURI,
      referrer: (qs.refer_url as string) ?? document.referrer,
    };

    if (qs.utm_source) {
      trackingParams.utmSource = qs.utm_source;
    }

    // SET THE COOKIE WITH THE SAME TRACKINGID AND NEW REFERRER AND URL
    cookie.set(RS_CONVERSION_INFO, trackingParams, {
      expires: 365,
      path: '/',
    });

    window.analytics.load(window.RepairSmith.ANALYTICS_WRITE_KEY);

    // DELAY FOR AFTER PAGE TRACK
    window.setTimeout(() => {
      trackAnalyticsEvent('Visit', {
        ...trackingParams,
        nonInteraction: 1,
      });
    }, 500);

    datadogLogs.init({
      clientToken: DATA_DOG_CLIENT_API_KEY,
      datacenter: 'us',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      env: APP_ENV,
    } as any);

    if (window.VWO) {
      window.VWO.push([
        'onVariationApplied',
        (data: any) => {
          if (!data) {
            return;
          }

          const expId = data[1];
          const variationId = data[2];
          if (
            window._vwo_exp[expId] &&
            typeof window._vwo_exp[expId].comb_n[variationId] !== 'undefined'
          ) {
            const experimentViewed = {
              campaignId: expId,
              campaignName: window._vwo_exp[expId].name,
              variationId,
              variationName: window._vwo_exp[expId].comb_n[variationId],
            };

            setTimeout(() => {
              dispatch(addVwoExperimentViewed(experimentViewed));
              trackAnalyticsEvent('onVariationApplied', experimentViewed);
            }, 500);
          }
        },
      ]);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const analyticsReady = Boolean(window.analytics);
    if (analyticsReady) {
      let pageUrl = location.pathname.toLowerCase();
      const params: any = {};

      if (pageUrl.match('/my-repairs/car-health-reports/?')) {
        if (pageUrl !== '/my-repairs/car-health-reports') {
          pageUrl = '/my-repairs/car-health-reports/*';
          params.mpiId = location.pathname.substring(
            location.pathname.lastIndexOf('/') + 1
          );
        }
      } else if (pageUrl.match('/my-repairs/.+')) {
        pageUrl = '/my-repairs/*';
        params.refNum = location.pathname.substring(
          location.pathname.lastIndexOf('/') + 1
        );
      }

      params.path = pageUrl;
      window.analytics.page(pageUrl, params);
    }
  }, [location]);

  return children as React.ReactElement;
};

export default AnalyticsWrapper;
